import api from "../../../../../services/api";
import Sacops from "../../../../../components/jsons/routesTranparency/routesSacopAndLicitacoes";
import NavMenu from "../../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../../components/public/others/NavbarCM";
import Footer from "../../../../../components/public/others/Footer";
import ListContrato from "../../../../../components/public/transparency/sacop/List/contrato/ListAll";
import Loading from "../../../../../components/public/others/Loading";
import Search from "../../../../../components/public/others/ordinances/Utils";
import { TipoPortal } from "../../../../../services/variables";
import { useEffect, useState } from "react";
import ShowDocuments from "../../../../../components/public/others/ordinances/ShowResult";

export default function ListCardSacop(props) {
  const SacopFilter = props.name;
  const [listResult, setListResult] = useState([]);
  const [type, setType] = useState("");
  const [route, setRoute] = useState("");
  const [removeLoading, setRemoveLoading] = useState(false);

  const formatString = (data, keys) => {
    return data.map((item) => {
      const formattedItem = keys.reduce((acc, key) => {
        let value = item[key];
        if (key.toUpperCase().includes("DATA") && value) {
          value = dateRender(value);
        }

        if (key.toUpperCase().includes("CNPJ") && value) {
          value = value.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          );
        }

        if (key.toUpperCase().includes("VALOR") && typeof value !== "string") {
          value = moneyFormat(value);
        } else if (value == null) {
          value = "";
        } else if (typeof value !== "string") {
          value = value.toString();
        }

        acc[key] = value;
        return acc;
      }, {});
      return formattedItem;
    });
  };

  const moneyFormat = (money) => {
    return money
      ? money.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        })
      : "";
  };

  const dateRender = (dateReq) => {
    if (!dateReq) return "";
    const date = new Date(dateReq);
    date.setDate(date.getDate() + 1);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (type) {
      api
        .get(`/sacop/list/all/${type}`)
        .then((res) => {
          if (!res.data.err) {
            const result = res.data.res;
            const formattedResult =
              result.length > 0
                ? formatString(result, Object.keys(result[0]))
                : [];
            setListResult(formattedResult);
          } else {
            setListResult([]);
          }
          setRemoveLoading(true);
        })
        .catch((err) => {
          console.error("Failed to fetch data", err);
          setRemoveLoading(true);
        });
    }
  }, [type]);

  useEffect(() => {
    const selectedSacop = Object.values(Sacops).find(
      (sacop) => sacop?.TITLE === SacopFilter
    );
    if (selectedSacop) {
      setType(selectedSacop.SELECT);
      setRoute(selectedSacop.ROUTE);
    }
  }, [SacopFilter]);

  return (
    <>
      {/* Render based on TipoPortal */}
      {TipoPortal === 3 ? <NavMenu /> : <NavMenuCM />}
      <div className="section-title rotes_pages">
        <h6>{route}</h6>
      </div>
      <div className="section-title-portarias">
        <h4>{SacopFilter}</h4>
      </div>
      {SacopFilter == "Contratos" && (
        <ShowDocuments
          name={"Sem Movimentação - Contrato"}
          select={"Sem Movimentação - Contrato"}
        />
      )}
      {listResult.length > 0 ? (
        <ListContrato name={SacopFilter} data={listResult} />
      ) : (
        <>
          <Search />
          {!removeLoading && <Loading />}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "40px",
              justifyContent: "center",
            }}
          >
            <b>Nenhum item encontrado</b>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}
