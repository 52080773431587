const idPortalApi = "4e8a23d9cea208443fd469fac83c03efda77ca24";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "http://cmsantahelena.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmsantahelena.ma.gov.br",
  NomePortal: "Santa Helena",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#000080",

  //links externos
  link_youtube: `https://www.youtube.com/@TVCamaraSantaHelenaMA/streams`,
  link_localize: `https://maps.app.goo.gl/Xu7wyPQYkXZTWr5y5`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31894.30078508432!2d-45.3125321!3d-2.2333749!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92b31f7eb4edf5d1%3A0xf795243c8f28eda!2sSanta%20Helena%2C%20MA%2C%2065208-000!5e0!3m2!1spt-BR!2sbr!4v1720786840189!5m2!1spt-BR!2sbr",
};
